<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Contact With Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Conatct Info -->
<div class="pt-100 pb-70">
    <div class="container">
        <!-- <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info">
                    <i class='bx bxs-phone'></i>
                    <h4>Contact Number</h4>
                    <p> <a href="tel:+0123654987">+0123 654 987</a></p>
                    <p><a href="tel:+0123456789">+0123 456 789</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info">
                    <i class='bx bxs-location-plus'></i>
                    <h4>Our Location</h4>
                    <p>6th floor, anthina</p>
                    <p>Barbosa Sidney</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-info">
                    <i class='bx bxs-envelope'></i>
                    <h4>Contact Number</h4>
                    <p><a href="mailto:hello@lemonmode.com">hello@lemonmode.com</a></p>
                    <p><a href="mailto:info@lemonmode.com">info@lemonmode.com</a></p>
                </div>
            </div>
        </div> -->
    </div>
</div>
<!-- End Conatct Info -->

<!-- Contact Area -->
<div class="contact-form-area pb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Get in Touch</h2>
        </div>

        <div class="contact-form">
            <form id="contactForm" [formGroup]="contactFormGroup">
                <div class="row">
                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" name="name" [formControlName]="'name'" class="form-control" id="name"
                                placeholder="Your name" required>
                            <div
                                *ngIf="contactFormGroup.get('name').invalid && (contactFormGroup.get('name').dirty || contactFormGroup.get('name') .touched || isSubmit)">
                                <div *ngIf="contactFormGroup.get('name').hasError('required')" style="color: red;">Name
                                    is required.</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="email" name="email" [formControlName]="'email'" class="form-control" id="email"
                                placeholder="Your email address" required>
                            <div
                                *ngIf="contactFormGroup.get('email').invalid && (contactFormGroup.get('email').dirty || contactFormGroup.get('email') .touched || isSubmit)">
                                <div *ngIf="contactFormGroup.get('email').hasError('required')" style="color: red;">
                                    Email is required.</div>
                                <div *ngIf="contactFormGroup.get('email').hasError('email')" style="color: red;">
                                    Please enter a valid email address.</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" [formControlName]="'subject'" id="msg_subject"
                                class="form-control" placeholder="Your Subject" required>
                            <div
                                *ngIf="contactFormGroup.get('subject').invalid && (contactFormGroup.get('subject').dirty || contactFormGroup.get('subject') .touched || isSubmit)">
                                <div *ngIf="contactFormGroup.get('subject').hasError('required')" style="color: red;">
                                    subject is required.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="number" id="phone_number" class="form-control" formControlName="phone_number"
                                placeholder="Your phone number" />
                            <div
                                *ngIf="contactFormGroup.get('phone_number').invalid && (contactFormGroup.get('phone_number').dirty || contactFormGroup.get('phone_number') .touched || isSubmit)">
                                <div *ngIf="contactFormGroup.get('phone_number').hasError('required')"
                                    style="color: red;">
                                    Phone number is required.
                                </div>
                                <div *ngIf="contactFormGroup.get('phone_number').hasError('pattern')"
                                    style="color: red;">
                                    Invalid phone number. Please enter a 10-digit number.
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea name="message" id="message" [formControlName]="'message'" class="form-control"
                                cols="30" rows="6" required placeholder="Write your message..."></textarea>
                            <div
                                *ngIf="contactFormGroup.get('message').invalid && (contactFormGroup.get('message').dirty || contactFormGroup.get('message') .touched || isSubmit)">
                                <div *ngIf="contactFormGroup.get('message').hasError('required')" style="color: red;">
                                    Message is required.
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn-one" (click)="submitForm()">Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- Contact Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <!-- <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required=""
                            autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->